/* eslint-disable no-empty-pattern */
import { commonService, offersService } from 'app/services';
import { HttpStatusCode } from 'axios';
import moment from 'moment';
import { InputText } from 'primereact';
import { Calendar } from 'primereact/calendar';
import { InputNumber } from 'primereact/inputnumber';
import { InputTextarea } from 'primereact/inputtextarea';
import { forwardRef, memo, useImperativeHandle, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line react/prop-types
function AddEditCategory({ onChange }, refs) {
    const { t } = useTranslation();
    const [ID, setID] = useState({});
    const [mode, setMode] = useState(false);
    const [Title, setTitle] = useState('');
    const [Description, setDescription] = useState('');
    const [Discount, setDiscount] = useState('');
    const [StartDate, setStartDate] = useState('');
    const [EndDate, setEndDate] = useState('');
    const [show, setShowModal] = useState(false);
    useImperativeHandle(refs, () => ({
        /**
         * show dialog edit/add user
         * @param {Object} data
         */
        show: (data) => {
            if (!data.mode) {
                setID(data.ID);
                setTitle(data.Title);
                setDescription(data.Description);
                setDiscount(data.Discount);
                setStartDate(moment().utc(data.StartDate)._d);
                setEndDate(moment().utc(data.EndDate)._d);
            }
            setMode(data.mode);
            setShowModal(true);
        }
    }));
    /**
     * Reset data
     */
    const initData = () => {
        setTitle('');
        setDescription('');
        setDiscount('');
        setStartDate('');
        setEndDate('');
    };
    /**
     * Add user
     */
    const onAdd = async () => {
        const res = await offersService.create({
            Title,
            Description,
            Discount,
            StartDate,
            EndDate
        });
        if (res.status === HttpStatusCode.Ok) {
            commonService.toast.show({
                severity: 'success',
                summary: t('INFO_MESSAGE'),
                detail: t('M019')
            });
            setShowModal(false);
            initData();
            onChange(true);
        } else {
            commonService.toast.show({
                severity: 'error',
                summary: t('ERROR_MESSAGE'),
                detail: t('M043')
            });
        }
    };
    /**
     * Update user
     */
    const onEdit = async () => {
        const res = await offersService.update({ ID, Title, Description, Discount, StartDate, EndDate });
        if (res.status === HttpStatusCode.Ok) {
            commonService.toast.show({
                severity: 'success',
                summary: t('INFO_MESSAGE'),
                detail: t('M012')
            });
            setShowModal(false);
            initData();
            onChange(true);
        } else {
            commonService.toast.show({
                severity: 'error',
                summary: t('ERROR_MESSAGE'),
                detail: t('M011')
            });
        }
    };
    /**
     * Hide dialog
     */
    const onCancel = async () => {
        initData();
        setShowModal(false);
    };
    return (
        <Modal show={show} centered size='lg' backdrop='static' className={mode ? 'modal-edit-error' : 'modal-edit-error read-input'}>
            <Modal.Body className='p-0'>
                <h2 className='text-primary text-center fw-bold pt-4 fs-24'>{mode ? 'Add Offers' : 'Edit Offers'}</h2>
                <div className='col-12 row py-2 mx-0 px-4 pt-5 centered-control'>
                    <div className='col-3 ps-0'>
                        <label className='fs-md w-100 ps-3'>Title</label>
                    </div>
                    <div className='d-flex flex-column col-9 px-0'>
                        <InputText
                            id='name'
                            value={Title}
                            className='px-2 fs-md'
                            placeholder='Enter Title'
                            onChange={(e) => setTitle(e.target.value)}
                        />
                    </div>
                </div>
                <div className='col-12 row py-2 mx-0 px-4 pt-5 centered-control'>
                    <div className='col-3 ps-0'>
                        <label className='fs-md w-100 ps-3'>Description</label>
                    </div>
                    <div className='d-flex flex-column col-9 px-0'>
                        <InputTextarea
                            id='name'
                            value={Description}
                            className='px-2 fs-md'
                            placeholder='Enter Description'
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </div>
                </div>
                <div className='col-12 row py-2 mx-0 px-4 pt-5 centered-control'>
                    <div className='col-3 ps-0'>
                        <label className='fs-md w-100 ps-3'>Discount</label>
                    </div>
                    <div className='d-flex flex-column col-9 px-0'>
                        <InputNumber value={Discount} onChange={(e) => setDiscount(e.value)} className='w-100' id='priceBreakfast' />
                    </div>
                </div>
                <div className='col-12 row py-2 mx-0 px-4 pt-5 centered-control'>
                    <div className='col-3 ps-0'>
                        <label className='fs-md w-100 ps-3'>Start Date</label>
                    </div>
                    <div className='d-flex flex-column col-9 px-0'>
                        <Calendar
                            value={StartDate}
                            onChange={(e) => {
                                setStartDate(e.target.value);
                            }}
                            baseZIndex={1055}
                            inputClassName='p-calendar-input fs-md'
                            placeholder={t('PLACEHOLDER_SELECT_DATE')}
                            showButtonBar
                            showIcon
                            selectionMode='single'
                            className='w-100 fs-md'
                            icon='icon calendar'
                            readOnlyInput={true}
                            clearButtonClassName='border btn-cancel-sm rounded calendar-btn fs-md h-100'
                            todayButtonClassName='border btn-action-sm rounded calendar-btn fs-md text-white border-0 px-4'
                        />
                    </div>
                </div>
                <div className='col-12 row py-2 mx-0 px-4 pt-5 centered-control'>
                    <div className='col-3 ps-0'>
                        <label className='fs-md w-100 ps-3'>End Date</label>
                    </div>
                    <div className='d-flex flex-column col-9 px-0'>
                        <Calendar
                            value={EndDate}
                            onChange={(e) => {
                                setEndDate(e.target.value);
                            }}
                            baseZIndex={1055}
                            inputClassName='p-calendar-input fs-md'
                            placeholder={t('PLACEHOLDER_SELECT_DATE')}
                            showButtonBar
                            showIcon
                            selectionMode='single'
                            className='w-100 fs-md'
                            icon='icon calendar'
                            readOnlyInput={true}
                            clearButtonClassName='border btn-cancel-sm rounded calendar-btn fs-md h-100'
                            todayButtonClassName='border btn-action-sm rounded calendar-btn fs-md text-white border-0 px-4'
                        />
                    </div>
                </div>
                <div className='d-flex justify-content-center gap-2 py-4 border-top'>
                    <Button className='btn-w-120 rounded border btn-cancel-sm' variant='default' onClick={onCancel}>
                        {t('BTN_CANCEL')}
                    </Button>
                    <Button
                        className='btn-w-120 rounded text-white fw-bold btn-action-sm'
                        variant='default'
                        onClick={mode ? onAdd : onEdit}>
                        {t('BTN_OK')}
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
}
export default memo(forwardRef(AddEditCategory));
