import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    toggleSidebar: false
};
export const sidebar = createSlice({
    name: 'sidebar',
    initialState,
    reducers: {
        expandAction: (state, action) => {
            state.toggleSidebar = action.payload;
        }
    }
});
export const { expandAction } = sidebar.actions;
export default sidebar.reducer;
