import { LoginStatus } from 'app/constants';
import { authService, commonService } from 'app/services';
import { loginAction } from 'app/store/authentication';
import validatorChain from 'app/utils/validator-chain';
import { HttpStatusCode } from 'axios';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

export default function Login() {
    const dispatch = useDispatch();
    const [password, setPassword] = useState('');
    const [userName, setUserName] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const [error, setError] = useState({});
    const [msgLogin] = useState('');
    const validate = () => {
        const errors = {
            username: validatorChain(userName).isRequire('M001').getError(),
            password: validatorChain(password).isRequire('M002').getError()
        };
        setError(errors);
        return Object.values(errors).reduce((r, c) => (c === null ? r : false), true);
    };
    /**
     * call api to authen user
     */
    const verify = async (model) => {
        const res = await authService.getAuthentication(model);
        const { status, data } = res;

        if (status === HttpStatusCode.Ok) {
            if (data.errorCode === LoginStatus.success) {
                dispatch(loginAction(data.payload));
                commonService.preloader.hide();
                return true;
            }
            commonService.preloader.hide();
            return false;
        }
    };
    /**
     * submit data to authen
     */
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (validate()) {
            commonService.preloader.show();
            await verify({ username: userName, password, remenber_me: rememberMe });
        }
    };
    return (
        <div className='d-lg-flex half'>
            <div className='bg-login'></div>
            <div className='contents order-2 order-md-1 w-50'>
                <div className='container'>
                    <div className='row align-items-center justify-content-center half-content'>
                        <div className='col-md-7'>
                            <h3>
                                Login to <strong>Cordial Hotel</strong>
                            </h3>
                            <p className='mb-4'>Welcome to Cordial Hotel</p>
                            <div className='invalid-feedback'>{msgLogin}</div>
                            <Form onSubmit={handleSubmit}>
                                <div className='flex flex-column gap-2 py-3'>
                                    <label htmlFor='username' className='py-2'>
                                        Username
                                    </label>
                                    <InputText
                                        id='username'
                                        placeholder='Enter Username'
                                        value={userName}
                                        className='w-100'
                                        onChange={(e) => setUserName(e.target.value)}
                                    />
                                    <div className='invalid-feedback'>{error.userName}</div>
                                </div>
                                <div className='flex flex-column gap-2 py-3'>
                                    <label htmlFor='password' className='py-2'>
                                        Password
                                    </label>
                                    <InputText
                                        id='password'
                                        placeholder='Enter password'
                                        type='password'
                                        value={password}
                                        className='w-100'
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                    <div className='invalid-feedback'>{error.password}</div>
                                </div>
                                <div className='flex align-items-center py-3'>
                                    <Checkbox
                                        inputId='remember'
                                        name='pizza'
                                        value={rememberMe}
                                        onChange={(e) => setRememberMe(e.checked)}
                                        checked={rememberMe}
                                    />
                                    <label htmlFor='remember' className='ml-2 px-3'>
                                        Remember me
                                    </label>
                                </div>
                                <Button className='w-100' type='submit' variant='action'>
                                    Login
                                </Button>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
