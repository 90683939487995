import { LOGIN_URL, LOGOUT_URL, VERIFY_URL } from 'app/constants';
import { requestGet, requestPost } from 'app/utils/request';

/**
 * Get authentication from server
 * @param {Object} model store username , password , remember me option
 */
const getAuthentication = (model) => requestPost(LOGIN_URL, model);
/**
 * Check users login or not
 */
const checkAuth = async () => requestGet(VERIFY_URL);
/**
 * Handle user logout action
 */
const logout = () => {
    // send request
    return requestGet(LOGOUT_URL);
};
export default { getAuthentication, checkAuth, logout };
