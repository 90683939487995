import * as ROUTER from './routes';
export * from './api';
export * from './common';
export { ROUTER };
export const DISABILITY_LIST = [
    { value: 1, label: 'Access via exterior corridors' },
    { value: 2, label: 'Accessible bath' },
    { value: 3, label: 'Closed-captioned TV' },
    { value: 4, label: 'Doorbell and phone notification' },
    { value: 5, label: 'Low-height plug sockets in bathroom' },
    { value: 6, label: 'Visual fire alarm' },
    { value: 7, label: 'Wheelchair-accessible' },
    { value: 8, label: 'Wheelchair-width doorways' },
    { value: 9, label: 'Low-height view port in door' },
    { value: 10, label: 'Area rugs in room' },
    { value: 11, label: 'Grab bar in bath' },
    { value: 12, label: 'Hand-held shower head' },
    { value: 13, label: 'Hardwood flooring in room' },
    { value: 14, label: 'Height-adjustable shower head' },
    { value: 15, label: 'Portable shower seat' },
    { value: 16, label: 'Smooth flooring in room' },
    { value: 17, label: 'Thin carpet in room' },
    { value: 18, label: 'Tile flooring in room' },
    { value: 19, label: 'Visual doorbell notification' },
    { value: 20, label: 'Low-height door lock' },
    { value: 21, label: 'Low-height view port in door' }
];
export const BATHROOM_LIST = [
    { value: 1, label: 'Dressing gowns' },
    { value: 2, label: 'Bidet' },
    { value: 3, label: 'Free toiletries' },
    { value: 4, label: 'Hairdryer' },
    { value: 5, label: 'Private bathroom' },
    { value: 6, label: 'Separate bath and shower' },
    { value: 7, label: 'Shampoo' },
    { value: 8, label: 'Slippers' },
    { value: 9, label: 'Soap' },
    { value: 10, label: 'Toilet paper' },
    { value: 11, label: 'Toothbrush and toothpaste' },
    { value: 12, label: 'Towels' },
    { value: 13, label: 'Hydromassage showerhead' },
    { value: 14, label: 'Rainfall showerhead' },
    { value: 15, label: 'Designer toiletries' },
    { value: 16, label: 'Shower/bath combination' }
];
export const BEDROOM_LIST = [
    { value: 1, label: 'Air conditioning' },
    { value: 2, label: 'Bed sheets' },
    { value: 3, label: 'Blackout curtains' },
    { value: 4, label: 'Cots/infant beds (surcharge)' },
    { value: 5, label: 'Egyptian cotton bed sheets' },
    { value: 6, label: 'Pillow menu' },
    { value: 7, label: 'Premium bedding' },
    { value: 8, label: 'Rollaway/extra beds (surcharge)' },
    { value: 9, label: 'Select Comfort bed' },
    { value: 10, label: 'Separate bedroom' },
    { value: 11, label: 'Free cots/infant beds' },
    { value: 12, label: 'Hypo-allergenic bedding' },
    { value: 13, label: 'No rollaway/extra beds available' },
    { value: 14, label: 'Rollaway/extra beds (surcharge)' },
    { value: 15, label: 'No cots (infant beds) available' },
    { value: 16, label: 'Day bed' }
];
export const ENTERTAINMENT_LIST = [
    { value: 1, label: '40-inch Smart TV' },
    { value: 2, label: '42-inch Smart TV' },
    { value: 3, label: 'Cable channels' },
    { value: 4, label: 'Premium TV channels' },
    { value: 5, label: 'Satellite channels' }
];
export const INTERNET_LIST = [
    { value: 1, label: 'Free WiFi (100+ Mbps (good for 1–2 people or up to 6 devices))' },
    { value: 2, label: 'Free Wifi' }
];
export const FAMILY_LIST = [
    { value: 1, label: 'Baby bath' },
    { value: 2, label: 'Changing table' },
    { value: 3, label: 'children&apos;s books' },
    { value: 4, label: 'High chair' },
    { value: 5, label: 'Musical instruments' },
    { value: 6, label: 'Playpen' }
];
export const FOOD_LIST = [
    { value: 1, label: 'Champagne service' },
    { value: 2, label: 'Coffee/tea maker' },
    { value: 3, label: 'Electric kettle' },
    { value: 4, label: 'Free bottled water' },
    { value: 5, label: 'Minibar' },
    { value: 6, label: 'Paper towels' },
    { value: 7, label: 'Room service (limited)' },
    { value: 8, label: 'Mini fridge' },
    { value: 9, label: 'Cookware/dishes/utensils' },
    { value: 10, label: 'Dining area' },
    { value: 11, label: 'Microwave' },
    { value: 12, label: 'Free tea bags/instant coffee' },
    { value: 13, label: 'Kitchenette' },
    { value: 14, label: 'Stovetop' },
    { value: 15, label: 'Fridge' }
];
export const MORE_LIST = [
    { value: 1, label: 'Connecting rooms available' },
    { value: 2, label: 'Daily housekeeping' },
    { value: 3, label: 'Desk' },
    { value: 4, label: 'Fireplace' },
    { value: 5, label: 'Laptop workspace' },
    { value: 6, label: 'Phone' },
    { value: 7, label: 'Safe' },
    { value: 8, label: 'Soundproofed rooms' },
    { value: 9, label: 'Individually decorated' },
    { value: 10, label: 'Individually furnished' },
    { value: 11, label: 'View – mountain' },
    { value: 12, label: 'Wardrobe' },
    { value: 13, label: 'Shared accommodation' },
    { value: 14, label: '1 living room' },
    { value: 15, label: 'Located on the top floor' },
    { value: 16, label: 'Sitting area' },
    { value: 17, label: 'View – city' },
    { value: 18, label: 'Desk chair' },
    { value: 19, label: 'Dinning Table' },
    { value: 20, label: 'Fireplace' },
    { value: 21, label: 'View – ocean' },
    { value: 22, label: 'Views – city, partial sea' }
];

export const UTILITY = {
    DISABILITY: 'disability',
    BATHROOM: 'bathroom',
    BEDROOM: 'bedroom',
    ENTERTAINMENT: 'entertainment',
    FAMILY: 'family',
    FOOD: 'food',
    INTERNET: 'internet',
    MORE: 'more'
};
