import {
    BATHROOM_LIST,
    BEDROOM_LIST,
    DISABILITY_LIST,
    ENTERTAINMENT_LIST,
    FAMILY_LIST,
    FOOD_LIST,
    INTERNET_LIST,
    MORE_LIST,
    UTILITY
} from 'app/constants';
import { commonService } from 'app/services';
import roomService from 'app/services/room/room.service';
import _ from 'lodash';
import { Galleria } from 'primereact/galleria';
import { Image } from 'primereact/image';
import { RadioButton } from 'primereact/radiobutton';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
export default function RoomDetail() {
    const location = useLocation();
    const [activeIndex, setActiveIndex] = useState(0);
    const [allData, setData] = useState({});
    const [breakfastMain, setBreakfastMain] = useState({});
    /**
     * fetch data from db with ID
     * @returns
     */
    const fetchData = async () => {
        commonService.preloader.show();
        const data = await roomService.details({ ID: location.state });
        setData(data);
        setBreakfastMain({ check: false, price: data.Price?.Norm, priceSale: data.Price?.Sale });
        commonService.preloader.hide();
        return true;
    };
    useEffect(() => {
        commonService.preloader.hide();
        fetchData();
    }, []);
    const responsiveOptions = [
        {
            breakpoint: '991px',
            numVisible: 4
        },
        {
            breakpoint: '767px',
            numVisible: 3
        },
        {
            breakpoint: '575px',
            numVisible: 1
        }
    ];
    const itemTemplate = (item) => {
        return (
            <Image src={`${process.env.PUBLIC_URL}/image/room/${location.state}/${item}`} alt={item} style={{ width: '100%' }} preview />
        );
    };

    const getContentArray = (content) => {
        switch (content) {
            case UTILITY.DISABILITY:
                return DISABILITY_LIST;
            case UTILITY.BATHROOM:
                return BATHROOM_LIST;
            case UTILITY.BEDROOM:
                return BEDROOM_LIST;
            case UTILITY.ENTERTAINMENT:
                return ENTERTAINMENT_LIST;
            case UTILITY.INTERNET:
                return INTERNET_LIST;
            case UTILITY.FAMILY:
                return FAMILY_LIST;
            case UTILITY.FOOD:
                return FOOD_LIST;
            case UTILITY.MORE:
                return MORE_LIST;

            default:
                return [];
        }
    };
    const onChangeMain = (checked, price) => {
        // update data in current table
        const res = {
            price: _.isEmpty(price) ? breakfastMain.price : price.Norm,
            priceSale: _.isEmpty(price) ? breakfastMain.priceSale : price.Sale,
            check: checked
        };
        setBreakfastMain(res);
    };
    const utility = (content) => {
        const data = _.get(allData, `RoomAmenities.${content}`, []);
        const list = [];
        for (const item of data) {
            const dataList = getContentArray(content);
            const temp = dataList.find(({ value }) => value === item);
            const element = <li key={item}>{temp?.label}</li>;
            list.push(element);
        }
        return list;
    };
    const thumbnailTemplate = (item) => {
        return <img src={`${process.env.PUBLIC_URL}/image/room/${location.state}/${item}`} alt={item} />;
    };
    return (
        <div className='auto-container rooms h-100'>
            <div className='row w-100 card px-0 border-0 pt-5'>
                <Galleria
                    value={allData?.ImageList}
                    activeIndex={activeIndex}
                    onItemChange={(e) => setActiveIndex(e.index)}
                    responsiveOptions={responsiveOptions}
                    numVisible={5}
                    item={itemTemplate}
                    thumbnail={thumbnailTemplate}
                    showItemNavigators
                />
            </div>
            <div className='row room-details'>
                <div className='col-lg-8'>
                    <div className='d-flex justify-content-center'>
                        <div className='price w-100'>
                            <div className={_.isEmpty(allData.PriceBreakFast) ? 'd-none' : 'row mx-3'}>
                                <div className='col-md-3 px-0 col-sm-12'>
                                    <RadioButton
                                        inputId='noBreakfast'
                                        name='breakfasts'
                                        onChange={() => onChangeMain(false, allData.Price)}
                                        checked={!breakfastMain?.check || false}
                                    />
                                    <label htmlFor='noBreakfast' className='px-2 fs-md'>
                                        No Breakfast
                                    </label>
                                </div>
                                <div className='col-md-3 px-0 col-sm-12'>
                                    <RadioButton
                                        inputId='haveBreakfast_'
                                        name='breakfasts'
                                        onChange={() => onChangeMain(true, allData.PriceBreakFast)}
                                        checked={breakfastMain?.check || false}
                                    />
                                    <label htmlFor='haveBreakfast' className='px-2 fs-md'>
                                        Have Breakfast
                                    </label>
                                </div>
                            </div>
                            <div className='row p-2'>
                                <div className='col-md-3 price-original fp-20'>
                                    {Number(breakfastMain?.price || 0).toLocaleString('en')} VND
                                </div>
                                <div className='col-md-3 price-sale fp-20'>
                                    {Number(breakfastMain?.priceSale || 0).toLocaleString('en')} VND
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <h2 className='sec-title px-3'>{allData.Name}</h2>
                        <div className='room-title px-3'>{allData.Description}</div>
                    </div>
                    <div className='row py-4'>
                        <div className='col-md-6 py-2'>
                            <div className='d-flex align-items-center'>
                                <span className='icon blueprint mx-3'></span>
                                <span className='px-3 fs-md'>
                                    {allData.RoomAmenities?.main.blueprint}
                                    <span className='px-1'>
                                        m<sup>2</sup>
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div className='col-md-6 py-2'>
                            <div className='d-flex align-items-center'>
                                <span className='icon bed mx-3'></span>
                                <span className='px-3 fs-md'>{allData.RoomAmenities?.main.bed}</span>
                            </div>
                        </div>
                        <div className='col-md-6 py-2'>
                            <div className='d-flex align-items-center'>
                                <span className='icon people mx-3'></span>
                                <span className='px-3 fs-md'>{allData.RoomAmenities?.main.people}</span>
                            </div>
                        </div>
                        <div className='col-md-6 py-2'>
                            <div className='d-flex align-items-center'>
                                <span className='icon direction icon-sea mx-0'></span>
                                <span className='px-3 fs-md'>{allData.RoomAmenities?.main.direction}</span>
                            </div>
                        </div>
                    </div>
                    <h5 className='fw-bold px-3'> Tiện nghi phòng</h5>
                    <div className='row pb-4 px-3'>
                        <div className='col-md-6 py-2'>
                            <div className='d-flex align-items-center py-2'>
                                <span className='icon bed mx-1'></span>
                                <span className='px-3 fs-md fw-bold'>Hỗ trợ người khuyết tật</span>
                            </div>
                            <ul>{utility(UTILITY.DISABILITY)}</ul>
                            <div className='d-flex align-items-center py-2'>
                                <span className='icon bed mx-1'></span>
                                <span className='px-3 fs-md fw-bold'>Phòng ngủ</span>
                            </div>
                            <ul>{utility(UTILITY.BATHROOM)}</ul>
                            <div className='d-flex align-items-center py-2'>
                                <span className='icon bed mx-1'></span>
                                <span className='px-3 fs-md fw-bold'>Cho gia đình</span>
                            </div>
                            <ul>{utility(UTILITY.FAMILY)}</ul>
                            <div className='d-flex align-items-center py-2'>
                                <span className='icon bed mx-1'></span>
                                <span className='px-3 fs-md fw-bold'>Ăn uống</span>
                            </div>
                            <ul>{utility(UTILITY.FOOD)}</ul>
                        </div>
                        <div className='col-md-6 py-2'>
                            <div className='d-flex align-items-center py-2'>
                                <span className='icon bed mx-1'></span>
                                <span className='px-3 fs-md fw-bold'>Phòng tắm</span>
                            </div>
                            <ul>{utility(UTILITY.BEDROOM)}</ul>
                            <div className='d-flex align-items-center py-2'>
                                <span className='icon bed mx-1'></span>
                                <span className='px-3 fs-md fw-bold'>Giải trí</span>
                            </div>
                            <ul>{utility(UTILITY.ENTERTAINMENT)}</ul>
                            <div className='d-flex align-items-center py-2'>
                                <span className='icon bed mx-1'></span>
                                <span className='px-3 fs-md fw-bold'>Internet</span>
                            </div>
                            <ul>{utility(UTILITY.INTERNET)}</ul>
                            <div className='d-flex align-items-center py-2'>
                                <span className='icon bed mx-1'></span>
                                <span className='px-3 fs-md fw-bold'>Khác</span>
                            </div>
                            <ul>{utility(UTILITY.MORE)}</ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
