/* eslint-disable no-empty-pattern */
import { commonService, contactService } from 'app/services';
import { HttpStatusCode } from 'axios';
import { InputTextarea } from 'primereact/inputtextarea';
import { forwardRef, memo, useImperativeHandle, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line react/prop-types
function About({}, refs) {
    const { t } = useTranslation();
    const [Content, setContent] = useState('');
    const [ID, setID] = useState('');
    const [show, setShowModal] = useState(false);
    useImperativeHandle(refs, () => ({
        /**
         * show dialog edit/add user
         * @param {Object} data
         */
        show: async () => {
            setShowModal(true);
            fetchData();
        }
    }));
    /**
     * Reset data
     */
    const initData = () => {
        setContent('');
    };
    const fetchData = async () => {
        const res = await contactService.about();
        setContent(res.data.payload.Content);
        setID(res.data.payload.ID);
        commonService.preloader.hide();
    };
    /**
     * Update user
     */
    const onSave = async () => {
        const res = await contactService.updateAbout({ Content, ID });
        if (res.status === HttpStatusCode.Ok) {
            commonService.toast.show({
                severity: 'success',
                summary: t('INFO_MESSAGE'),
                detail: t('M012')
            });
            setShowModal(false);
            initData();
        } else {
            commonService.toast.show({
                severity: 'error',
                summary: t('ERROR_MESSAGE'),
                detail: t('M011')
            });
        }
    };
    /**
     * Hide dialog
     */
    const onCancel = async () => {
        initData();
        setShowModal(false);
    };
    return (
        <Modal show={show} centered size='lg' backdrop='static' className='modal-edit-error read-input'>
            <Modal.Body className='p-0'>
                <h2 className='text-primary text-center fw-bold pt-4 fs-24'>About</h2>
                <div className='col-12 row py-2 mx-0 px-4 pt-5 centered-control'>
                    <div className='d-flex flex-column col-12 px-0'>
                        <InputTextarea
                            id='name'
                            value={Content}
                            className='px-2 fs-md'
                            placeholder='Enter Content'
                            onChange={(e) => setContent(e.target.value)}
                        />
                    </div>
                </div>
                <div className='d-flex justify-content-center gap-2 py-4 border-top'>
                    <Button className='btn-w-120 rounded border btn-cancel-sm' variant='default' onClick={onCancel}>
                        {t('BTN_CANCEL')}
                    </Button>
                    <Button className='btn-w-120 rounded text-white fw-bold btn-action-sm' variant='default' onClick={onSave}>
                        {t('BTN_OK')}
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
}
export default memo(forwardRef(About));
