import { SORT_ORDER, TAG_URL, TAG_VERIFY_NAME } from 'app/constants';
import { requestDelete, requestGet, requestPost, requestPut } from 'app/utils/request';
import { HttpStatusCode } from 'axios';
/**
 * Handle get list tag
 * @param {Object} value search content
 */
const search = async (value) => {
    // init params
    const { searchField, sortField, sortOrder, first, rows } = value;
    const params = {
        first,
        searchField,
        rows,
        sortField,
        sortOrder: sortOrder === 1 ? SORT_ORDER.ASC : SORT_ORDER.DESC
    };
    const res = await requestGet(TAG_URL, { params }); // call api get data
    // get status and data from response
    const { status, data } = res;
    if (status === HttpStatusCode.Ok) {
        // return search data
        return { ...data.payload };
    } else {
        return {
            data: [],
            total: 0
        };
    }
};
/**
 * Request to verify user data from server
 * @param {Object} data store tag data
 */
const verifyName = (data) => requestPost(TAG_VERIFY_NAME, data);

/* Request to create user data from server
 * @param {Object} data store tag data
 */
const create = (data) => requestPost(TAG_URL, data);
/* Request to create user data from server
 * @param {Object} data store tag data
 */
const update = (data) => requestPut(TAG_URL, data);
/* Request to create user data from server
 * @param {Object} data store tag data
 */
const remove = (data) => requestDelete(TAG_URL, { data });
/* Request to create user data from server
 * @param {Object} data store tag data
 */
export default { search, verifyName, create, update, remove };
