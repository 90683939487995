import { Header, SideBar } from 'app/views/core';
import { Outlet } from 'react-router-dom';

// eslint-disable-next-line react/prop-types
export default function MasterLayout({ withoutSideBar, withoutHeader }) {
    return (
        <div className='page'>
            <div className='row mx-0 page__content'>
                {withoutSideBar ? (
                    <div className='col-12 d-flex h-100 px-0'>
                        <div className='h-100-vh flex-fill w-content overflow-hidden with-sidebar'>
                            {!withoutHeader && <Header showLogo={withoutSideBar} />}
                            <Outlet />
                        </div>
                    </div>
                ) : (
                    <div className='col-12 d-flex h-100 px-0'>
                        <SideBar sideBarMode={true} />
                        <div className='right-content w-content'>
                            {!withoutHeader && <Header withoutSideBar={withoutSideBar} showLogo={withoutSideBar} />}
                            <Outlet />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
