import environments from 'environments';
import _ from 'lodash';
import { useCallback } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

export function withBaseURL(router) {
    return (environments.BASE_NAME || '') + router;
}
export function generateURL(path, params, base = environments.API_ENDPOINT) {
    const url = new URL(base + path, base);
    url.search = new URLSearchParams(params);
    return url.toString();
}
export const sleep = (millisecond) => new Promise((rs) => setTimeout(rs, millisecond));
/**
 * parse query string to object
 * @param {String} queryString query string
 *
 * @returns {Object}
 */
export function parseQueryParam(queryString) {
    const query = new URLSearchParams(queryString);
    const params = {};
    for (const [key, value] of query.entries()) {
        params[key] = value;
    }
    return params;
}
export function generateScreenPath(route, screen, params) {
    const screens = _.isArray(screen) ? screen.join('/') : screen;
    return generatePath(route + '/' + screens, params);
}
export function useRedirect() {
    const navigate = useNavigate();
    return useCallback(
        (route, screen, params = {}, state = {}) =>
            navigate(generateScreenPath(route, screen, params), { relative: 'route', replace: false, state }),
        [navigate]
    );
}
