export const LOGIN = '/login';
export const SERVICE = '/services';
export const BLOG = '/blogs';
export const ADD_BLOG = '/add-blog';
export const BLOG_DETAIL = '/blog-detail';
export const EDIT_BLOG = '/edit-blog/*';
export const GALLERY = '/gallery';
export const ROOM = '/rooms';
export const CAROUSEL = '/carousel';
export const ROOM_DETAIL = '/room-detail/*';
export const ROOM_IMAGE = '/room-detail/image/*';
export const EDIT_ROOM = '/edit/*';
export const ADD_ROOM = '/add-room';
export const CONTACT = '/contact';
export const EVENT_DETAIL = './event-detail';
export const EVENT = '/events';
export const MEETING_DETAIL = '/meeting-detail';
export const MEETING = '/meetings';
export const TOUR_DETAIL = '/tour-detail';
export const TOUR = '/tours';
export const ABOUT_US = '/about';
export const ABOUT_CORDIAL = '/cordial-hotel';
export const ABOUT_CORDIAL_GRAND = '/cordial-grand-hotel';
export const BASE_NAME = '/';
export const USER_MANAGEMENT = '/user-management';
export const CATEGORY = '/category';
export const TAG = '/tag';
export const GYM = '/gym-fitness';
export const SPA = '/spa-massage';
export const RESTAURANT = '/restaurant';
export const CABANON_RESTAURANT = '/cabanon-restaurant';
export const UTILITIES_ROOM = '/utilities';
export const COFFEE = '/coffee';
export const SWIMMING = '/swimming-pool';
export const ID = ':id';
export const OFFERS = '/offers';
export const PERMISSION = {
    1: [],
    2: []
};
