/* eslint-disable no-empty-pattern */
import { enumRole, ROLE, VARCHAR_MAX_LENGTH, VARCHAR_MIN_LENGTH } from 'app/constants';
import { commonService, userManagementService } from 'app/services';
import validatorChain from 'app/utils/validator-chain';
import { HttpStatusCode } from 'axios';
import moment from 'moment';
import { Dropdown, InputText } from 'primereact';
import { Calendar } from 'primereact/calendar';
import { RadioButton } from 'primereact/radiobutton';
import { forwardRef, memo, useImperativeHandle, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line react/prop-types
function AddEditUser({ onChange }, refs) {
    const { t } = useTranslation();
    const optionRole = ROLE;
    const [ID, setID] = useState({});
    const [mode, setMode] = useState(false);
    const [UserName, setUserName] = useState('');
    const [Name, setName] = useState('');
    const [Email, setEmail] = useState('');
    const [Birthday, setBirthday] = useState('');
    const [error, setError] = useState({});
    const [Gender, setGender] = useState('');
    const [Role, setRole] = useState(enumRole.user);
    const [Address, setAddress] = useState('');
    const [PhoneNumber, setPhoneNumber] = useState('');
    const [show, setShowModal] = useState(false);
    const disabledDay = moment({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    useImperativeHandle(refs, () => ({
        /**
         * show dialog edit/add user
         * @param {Object} data
         */
        show: (data) => {
            if (!data.mode) {
                setID(data.ID);
                setRole(data.Role);
                setUserName(data.UserName);
                setAddress(data.Address);
                setEmail(data.Email);
                setName(data.Name);
                setGender(data.Gender);
                setBirthday(data.Birthday);
                setPhoneNumber(data.PhoneNumber);
            }
            setMode(data.mode);
            setShowModal(true);
        }
    }));
    /**
     * Reset data
     */
    const initData = () => {
        setName('');
        setRole(enumRole.user);
        setEmail('');
        setBirthday('');
        setPhoneNumber('');
        setGender(false);
        setUserName('');
        setAddress('');
        setError({});
    };
    /**
     * Validate data
     * @returns Boolean
     */
    const validateData = async () => {
        const isDuplicateName = await verifyAccountID();
        const abc = UserName ? UserName.trim() : '';
        const errors = {
            UserName: validatorChain(abc)
                .isRequire('M001')
                .isLength({ min: VARCHAR_MIN_LENGTH, max: VARCHAR_MAX_LENGTH }, 'M003')
                .custom(() => !isDuplicateName, 'M002')
                .getError(),
            Name: validatorChain(Name.trim())
                .isRequire('M001')
                .isLength({ min: VARCHAR_MIN_LENGTH, max: VARCHAR_MAX_LENGTH }, 'M003')
                .getError(),
            Email: validatorChain(Email)
                .isRequire('M001')
                .isLength({ min: VARCHAR_MIN_LENGTH, max: VARCHAR_MAX_LENGTH }, 'M003')
                .getError(),
            PhoneNumber: validatorChain(PhoneNumber)
                .isRequire('M001')
                .isLength({ min: VARCHAR_MIN_LENGTH, max: VARCHAR_MAX_LENGTH }, 'M003')
                .getError(),
            Address: validatorChain(Address)
                .isRequire('M001')
                .isLength({ min: VARCHAR_MIN_LENGTH, max: VARCHAR_MAX_LENGTH }, 'M003')
                .getError(),
            Birthday: validatorChain(Birthday).isRequire('M001').getError()
        };
        setError(errors);
        return Object.values(errors).reduce((r, c) => (c === null ? r : false), true);
    };
    /**
     * Add user
     */
    const addUser = async () => {
        if (await validateData()) {
            const res = await userManagementService.create({
                UserName,
                Name,
                Email,
                PhoneNumber,
                Address,
                Gender,
                Role,
                Birthday
            });
            if (res.status === HttpStatusCode.Ok) {
                commonService.toast.show({
                    severity: 'success',
                    summary: t('INFO_MESSAGE'),
                    detail: t('M019')
                });
                setShowModal(false);
                initData();
                onChange(true);
            } else {
                commonService.toast.show({
                    severity: 'error',
                    summary: t('ERROR_MESSAGE'),
                    detail: t('M043')
                });
            }
        }
    };
    /**
     * Update user
     */
    const editUser = async () => {
        const res = await userManagementService.update({ ID, Role, Name, Email, PhoneNumber, Address, Gender, Birthday });
        if (res.status === HttpStatusCode.Ok) {
            commonService.toast.show({
                severity: 'success',
                summary: t('INFO_MESSAGE'),
                detail: t('M012')
            });
            setShowModal(false);
            initData();
            onChange(true);
        } else {
            commonService.toast.show({
                severity: 'error',
                summary: t('ERROR_MESSAGE'),
                detail: t('M011')
            });
        }
    };
    /**
     * Hide dialog
     */
    const onCancel = async () => {
        initData();
        setShowModal(false);
    };
    /**
     * Verify duplicate account name
     * @returns Boolean
     */
    const verifyAccountID = async () => {
        const result = await userManagementService.verifyUsername({ UserName });
        if (result.status === HttpStatusCode.Ok) {
            return result.data.payload;
        }
        return commonService.toast.show({
            severity: 'error',
            summary: t('ERROR_MESSAGE'),
            detail: t('M044')
        });
    };
    return (
        <Modal show={show} centered size='md' backdrop='static' className={mode ? 'modal-edit-error' : 'modal-edit-error read-input'}>
            <Modal.Body className='p-0'>
                <h2 className='text-primary text-center fw-bold pt-4 fs-24'>{mode ? t('BTN_ADD_USER') : t('LABEL_EDIT_USER')}</h2>
                <div className='col-12 row py-2 mx-0 px-4 pt-5 centered-control'>
                    <div className='col-3 ps-0'>
                        <label className='fs-md w-100 ps-3'>{t('TABLE_LABEL_ID')}</label>
                    </div>
                    <div className='d-flex flex-column col-9 px-0'>
                        <InputText
                            id='username'
                            value={UserName}
                            className='px-2 fs-md'
                            disabled={!mode}
                            placeholder={t('PLACEHOLDER_ENTER_USERNAME')}
                            onChange={(e) => setUserName(e.target.value)}
                        />
                    </div>
                </div>
                <div className='col-12 row mx-0 px-4 centered-control'>
                    <div className='col-3 ps-0'></div>
                    <div className='d-flex flex-column col-9 px-0'>
                        <label className='break-work text-danger'>{t(error['UserName'])}</label>
                    </div>
                </div>
                <div className='col-12 row mx-0 py-3 px-4 centered-control'>
                    <div className='col-3 ps-0'>
                        <label className='fs-md w-100 ps-3'>{t('TABLE_LABEL_NAME')}</label>
                    </div>
                    <div className='d-flex flex-column col-9 px-0'>
                        <InputText
                            id='name'
                            value={Name}
                            className='px-2 fs-md'
                            onChange={(e) => setName(e.target.value)}
                            placeholder={t('PLACEHOLDER_ENTER_NAME')}
                        />
                    </div>
                </div>
                <div className='col-12 row mx-0 px-4 centered-control'>
                    <div className='col-3 ps-0'></div>
                    <div className='d-flex flex-column col-9 px-0'>
                        <label className='break-work text-danger'>{t(error['Name'])}</label>
                    </div>
                </div>
                <div className='col-12 row mx-0 py-2 px-4 centered-control'>
                    <div className='col-3 ps-0'>
                        <label className='fs-md w-100 ps-3'>{t('TABLE_LABEL_EMAIL')}</label>
                    </div>
                    <div className='d-flex flex-column col-9 px-0'>
                        <InputText
                            id='email'
                            value={Email}
                            className='px-2 fs-md'
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder={t('PLACEHOLDER_ENTER_EMAIL')}
                        />
                    </div>
                </div>
                <div className='col-12 row mx-0 px-4 centered-control'>
                    <div className='col-3 ps-0'></div>
                    <div className='d-flex flex-column col-9 px-0'>
                        <label className='break-work text-danger'>{t(error['Email'])}</label>
                    </div>
                </div>
                <div className='col-12 row mx-0 pt-3 pb-2 px-4 centered-control'>
                    <div className='col-3 ps-0'>
                        <label className='fs-md w-100 ps-3'>{t('TABLE_LABEL_PHONE_NUMBER')}</label>
                    </div>
                    <div className='d-flex flex-column col-9 px-0'>
                        <InputText
                            id='phone'
                            value={PhoneNumber}
                            className='px-2 fs-md'
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            placeholder={t('PLACEHOLDER_ENTER_PHONE')}
                        />
                    </div>
                </div>
                <div className='col-12 row mx-0 px-4 centered-control'>
                    <div className='col-3 ps-0'></div>
                    <div className='d-flex flex-column col-9 px-0'>
                        <label className='break-work text-danger'>{t(error['PhoneNumber'])}</label>
                    </div>
                </div>
                <div className='col-12 row mx-0 pt-3 pb-2 px-4 centered-control'>
                    <div className='col-3 ps-0'>
                        <label className='fs-md w-100 ps-3'>{t('TABLE_LABEL_ADDRESS')}</label>
                    </div>
                    <div className='d-flex flex-column col-9 px-0'>
                        <InputText
                            id='address'
                            value={Address}
                            className='px-2 fs-md'
                            onChange={(e) => setAddress(e.target.value)}
                            placeholder={t('PLACEHOLDER_ENTER_ADDRESS')}
                        />
                    </div>
                </div>
                <div className='col-12 row mx-0 px-4 centered-control'>
                    <div className='col-3 ps-0'></div>
                    <div className='d-flex flex-column col-9 px-0'>
                        <label className='break-work text-danger'>{t(error['Address'])}</label>
                    </div>
                </div>
                <div className='col-12 row mx-0 pt-3 pb-2 px-4 centered-control'>
                    <div className='col-3 ps-0'>
                        <label className='fs-md w-100 ps-3'>{t('TABLE_LABEL_BIRTH_DAY')}</label>
                    </div>
                    <div className='d-flex flex-column col-9 px-0'>
                        <Calendar
                            value={Birthday}
                            onChange={(e) => {
                                setBirthday(e.target.value);
                            }}
                            baseZIndex={1055}
                            maxDate={disabledDay.toDate()}
                            inputClassName='p-calendar-input fs-md'
                            placeholder={t('PLACEHOLDER_SELECT_DATE')}
                            showButtonBar
                            showIcon
                            selectionMode='single'
                            className='w-100 fs-md'
                            icon='icon calendar'
                            readOnlyInput={true}
                            clearButtonClassName='border btn-cancel-sm rounded btn btn-size-sm calendar-btn fs-md btn-w-100'
                            todayButtonClassName='border btn-action-sm rounded btn btn-size-sm calendar-btn fs-md text-white border-0 btn-w-100'
                        />
                    </div>
                </div>
                <div className='col-12 row mx-0 px-4 centered-control'>
                    <div className='col-3 ps-0'></div>
                    <div className='d-flex flex-column col-9 px-0'>
                        <label className='break-work text-danger'>{t(error['Birthday'])}</label>
                    </div>
                </div>
                <div className='col-12 row mx-0 pt-3 pb-2 px-4 centered-control'>
                    <div className='col-3 ps-0'>
                        <label className='fs-md w-100 ps-3'>{t('TABLE_LABEL_GENDER')}</label>
                    </div>
                    <div className='d-flex col-9 px-0 row'>
                        <div className='d-flex align-items-center col-6'>
                            <RadioButton inputId='male' name='Male' value={false} onChange={(e) => setGender(e.value)} checked={!Gender} />
                            <label htmlFor='male' className='px-3 fs-md'>
                                {t('LABEL_MALE')}
                            </label>
                        </div>
                        <div className='d-flex align-items-center col-6'>
                            <RadioButton
                                inputId='female'
                                name='Female'
                                value={true}
                                onChange={(e) => setGender(e.value)}
                                checked={Gender}
                            />
                            <label htmlFor='female' className='px-3 fs-md'>
                                {t('LABEL_FEMALE')}
                            </label>
                        </div>
                    </div>
                </div>
                <div className='col-12 row mx-0 py-3 px-4 pb-5 centered-control'>
                    <div className='col-3 ps-0'>
                        <label className='fs-md w-100 ps-3'>{t('TABLE_LABEL_ROLE')}</label>
                    </div>
                    <div className='d-flex flex-column col-9 px-0'>
                        <Dropdown
                            className='w-100 min-h-40px'
                            appendTo='self'
                            options={optionRole}
                            value={Role}
                            onChange={(e) => setRole(e.value)}
                        />
                    </div>
                </div>
                <div className='d-flex justify-content-center gap-2 py-4 border-top'>
                    <Button className='btn-w-120 rounded border btn-cancel-sm' variant='default' onClick={onCancel}>
                        {t('BTN_CANCEL')}
                    </Button>
                    <Button
                        className='btn-w-120 rounded text-white fw-bold btn-action-sm'
                        variant='default'
                        onClick={mode ? addUser : editUser}>
                        {t('BTN_OK')}
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
}
export default memo(forwardRef(AddEditUser));
