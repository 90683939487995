import { ABOUT_URL, CONTACT_URL, SORT_ORDER } from 'app/constants';
import { requestDelete, requestGet, requestPut } from 'app/utils/request';
import { HttpStatusCode } from 'axios';
/**
 * Handle get list category
 * @param {Object} value search content
 */
const search = async (value) => {
    // init params
    const { searchField, sortField, sortOrder, first, rows } = value;
    const params = {
        first,
        searchField,
        rows,
        sortField,
        sortOrder: sortOrder === 1 ? SORT_ORDER.ASC : SORT_ORDER.DESC
    };
    const res = await requestGet(CONTACT_URL, { params }); // call api get data
    // get status and data from response
    const { status, data } = res;
    if (status === HttpStatusCode.Ok) {
        // return search data
        return { ...data.payload };
    } else {
        return {
            data: [],
            total: 0
        };
    }
};
/* Request to create user data from server
 * @param {Object} data store category data
 */
const remove = (data) => requestDelete(CONTACT_URL, { data });
const about = () => requestGet(ABOUT_URL);
/* Request to create user data from server
 * @param {Object} data store category data
 */
const updateAbout = (data) => requestPut(ABOUT_URL, data);
export default { search, remove, about, updateAbout };
