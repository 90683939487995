import { createSlice } from '@reduxjs/toolkit';
import { LANG_EN, ROUTER } from 'app/constants';
import { authService } from 'app/services';
import { forceLogout } from 'app/services/core/broadcast-channel';

const initialState = {
    userInfo: {
        username: '',
        name: '',
        id: 0,
        permission: 0,
        lang: LANG_EN
    },
    authenticated: false,
    verify: false,
    connected: false
};
export const logoutAction = async () => {
    try {
        await authService.logout();
        window.location.href = ROUTER.LOGIN;
    } catch (error) {
        forceLogout();
        window.location.href = ROUTER.LOGIN;
    }
};
export const authentication = createSlice({
    name: 'authentication',
    initialState,
    reducers: {
        loginAction: (state, action) => {
            state.userInfo = action.payload;
            state.authenticated = true;
            state.verify = true;
            // socketService.open();
        },
        verifyAction: (state) => {
            state.verify = true;
        },
        connectionAction: (state, action) => {
            state.connected = action.payload;
        },
        reloadAction: (state, action) => {
            state.needReload = action.payload;
        }
    }
});
export const { loginAction, connectionAction, verifyAction } = authentication.actions;
export default authentication.reducer;
