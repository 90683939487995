import { DATE_FORMAT_SHOW, DEFAULT_SORT_DIR, enumRole, INTEGER_MAX_VALUE, INTEGER_MIN_VALUE, ROLE, SORT_ORDER } from 'app/constants';
import { t } from 'i18next';
import _ from 'lodash';
import moment from 'moment';
import { Column } from 'primereact';
import { useCallback } from 'react';

/**
 * date template
 * @param {Object} rowData row data
 * @param {Object} column column data
 * @returns
 */
export const dateTemplate = (rowData, column) => {
    if (!rowData[column.field]) {
        return null;
    }
    return moment(rowData[column.field]).format(DATE_FORMAT_SHOW);
};
/**
 * No template
 * @param {Object} rowData row data
 * @param {Object} column column data
 * @returns
 */
export function useNoTemplate() {
    return useCallback((_rowData, column) => {
        return <span>{column.rowIndex + 1}</span>;
    }, []);
}
/**
 * Render create by user
 * @param {Number} role
 * @param {Func} t
 * @returns
 */
export const createByTemplate = (role, t) => {
    return role === enumRole.admin ? (
        <Column field='CreatedBys' header={t('TABLE_LABEL_CREATE_BY')} className='fs-md px-2' sortable={true} style={{ width: '10%' }} />
    ) : null;
};
/**
 * Render updated by user
 * @param {Number} role
 * @param {Func} t
 * @returns
 */
export const updateByTemplate = (role, t) => {
    return role === enumRole.admin ? (
        <Column field='UpdateBys' header={t('TABLE_LABEL_UPDATE_BY')} className='fs-md px-2' sortable={true} style={{ width: '10%' }} />
    ) : null;
};
/**
 * Render target model template
 * @param {Object} rowData row data
 */
export const sortLocal = (sortField, sortOrder, data) => {
    return _.orderBy(
        data,
        [(item) => item[sortField].toLowerCase()],
        sortOrder === DEFAULT_SORT_DIR ? [SORT_ORDER.DESC.toLowerCase()] : [SORT_ORDER.ASC.toLowerCase()]
    );
};
/**
 *
 * @param {String} value
 * @param {Number} max
 * @returns
 */
export const checkMaxByte = (value, max) => {
    return new Blob([value]).size > max;
};

/**
 * role template
 * @param {Object} rowData contains rows data
 */
export const roleSelectTemplate = (item) => {
    if (item) {
        const mapRole = _.keyBy(ROLE, 'value');
        return (
            <label className='item-select'>
                <span className='mb-0'>{t(mapRole[item].label)}</span>
                <span className='pe-1 character-s'>,</span>
            </label>
        );
    }
    return t('PLACEHOLDER_SELECT_ROLE');
};

export const isInRange = (number, options) => number < (options?.min ?? INTEGER_MIN_VALUE) || number > (options?.max ?? INTEGER_MAX_VALUE);
