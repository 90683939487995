import { isEqual } from 'lodash';

const isDirty = (obj, base) => {
    if (!obj || !base) {
        return false;
    }

    for (const [key, value] of Object.entries(obj)) {
        if (!isEqual(value, base[key])) {
            return true;
        }
    }

    return false;
};
/**
 *
 * @param {string} str
 */
export function fileExt(str) {
    return '.' + str.split('.').pop().toLowerCase();
}
export { isDirty };
