/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { ROUTER } from 'app/constants';
import { blogService, categoryService, commonService, tagService } from 'app/services';
import { HttpStatusCode } from 'axios';
import { t } from 'i18next';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { MultiSelect } from 'primereact/multiselect';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
// import { redirect } from 'react-router-dom';
export default function AddBlog() {
    // const location = useLocation();
    const [Title, setTitle] = useState('');
    const [selectedTag, setTag] = useState([]);
    const [SpecialContent, setSpecialContent] = useState('');
    const [Content, setContent] = useState('');
    const [optionCategory, setOptionCategory] = useState([]);
    const [optionTag, setOptionTag] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState();
    const navigate = useNavigate();
    /**
     * Add blog
     */
    // eslint-disable-next-line no-unused-vars
    const add = async () => {
        const res = await blogService.create({
            Title,
            Content,
            Images: '',
            Category: selectedCategory,
            Tag: selectedTag,
            SpecialContent
        });
        if (res.status === HttpStatusCode.Ok) {
            commonService.toast.show({
                severity: 'success',
                summary: t('INFO_MESSAGE'),
                detail: t('M019')
            });
            navigate(ROUTER.BLOG);
        } else {
            commonService.toast.show({
                severity: 'error',
                summary: t('ERROR_MESSAGE'),
                detail: t('M043')
            });
        }
    };
    const getCategory = async () => {
        const data = await categoryService.search({ searchField: '', sortField: 'UpdatedAt', sortOrder: 1, first: 0, rows: 1000 });
        setOptionCategory(data.data);
    };
    const getTag = async () => {
        const data = await tagService.search({ searchField: '', sortField: 'UpdatedAt', sortOrder: 1, first: 0, rows: 1000 });
        setOptionTag(data.data);
    };
    /**
     * Fetch first render data
     */
    useEffect(() => {
        commonService.preloader.hide();
        getCategory();
        getTag();
    }, []);
    return (
        <div>
            <div className='auto-container row container-rooms pb-4'>
                <div className='col-12'>
                    <h2 className='fw-bold'>Add Blog</h2>
                    <div className='row'>
                        <div className='col-md-4 py-2'>
                            <label htmlFor='title'>Title</label>
                            <InputText value={Title} onChange={(e) => setTitle(e.target.value)} className='w-100' id='title' />
                        </div>
                        <div className='col-md-4 py-2'>
                            <label htmlFor='Category'>Category</label>
                            <Dropdown
                                className='w-100 fs-md ms-3 multi-select-systems'
                                value={selectedCategory}
                                options={optionCategory}
                                optionLabel='Name'
                                optionValue='ID'
                                onChange={(e) => {
                                    setSelectedCategory(e.value);
                                }}
                            />
                        </div>
                        <div className='col-md-4 py-2'>
                            <label htmlFor='Tag'>Tag</label>
                            <MultiSelect
                                value={selectedTag}
                                onChange={(e) => setTag(e.value)}
                                options={optionTag}
                                optionLabel='Name'
                                optionValue='ID'
                                maxSelectedLabels={3}
                                id='disability'
                                className='w-100'
                            />
                        </div>
                        <div className='col-md-12 py-2'>
                            <label htmlFor='specialcontent'>SpecialContent</label>
                            <InputTextarea
                                value={SpecialContent}
                                onChange={(e) => setSpecialContent(e.target.value)}
                                className='w-100'
                                id='specialcontent'
                                rows={5}
                                cols={20}
                            />
                        </div>
                        <div className='col-md-12 py-2'>
                            <label htmlFor='content'>Content</label>
                            <CKEditor
                                editor={ClassicEditor}
                                data={Content}
                                onReady={(editor) => {
                                    // You can store the "editor" and use when it is needed.
                                    console.log('Editor is ready to use!', editor);
                                }}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setContent(data);
                                    // console.log({ event, editor, data });
                                }}
                                onBlur={(event, editor) => {
                                    // console.log('Blur.', editor);
                                }}
                                onFocus={(event, editor) => {
                                    // console.log('Focus.', editor);
                                }}
                            />
                        </div>
                        <div className='row mx-0 py-4 d-flex justify-content-center align-items-center'>
                            <Button className='btn-cancel-sm mx-2' variant='default' onClick={() => add()}>
                                Add
                            </Button>
                            <Button className='btn-action-sm mx-2' variant='default'>
                                Cancel
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
