import { SORT_ORDER, UTILITIES_URL, UTILITIES_VERIFY } from 'app/constants';
import { requestDelete, requestGet, requestPost, requestPut } from 'app/utils/request';
import { HttpStatusCode } from 'axios';
/**
 * Handle get list ROOM
 * @param {Object} value search content
 */
const search = async (value) => {
    // init params
    const { first, rows, sortField, sortOrder, searchField, selectedFilter } = value;
    const params = {
        first,
        searchField,
        rows,
        sortField,
        selectedFilter,
        sortOrder: sortOrder === 1 ? SORT_ORDER.ASC : SORT_ORDER.DESC
    };
    const res = await requestGet(UTILITIES_URL, { params }); // call api get data
    // get status and data from response
    const { status, data } = res;
    if (status === HttpStatusCode.Ok) {
        // return search data
        return data.payload;
    } else {
        return {
            data: []
        };
    }
};
/**
 * Request to verify user data from server
 * @param {Object} data store user management data
 */
const verifyName = (data) => requestPost(UTILITIES_VERIFY, data);

/* Request to create user data from server
 * @param {Object} data store user management data
 */
const create = (data) => requestPost(UTILITIES_URL, data);
/* Request to create user data from server
 * @param {Object} data store user management data
 */
const update = (data) => requestPut(UTILITIES_URL, data);
/* Request to create user data from server
 * @param {Object} data store user management data
 */
const remove = (data) => requestDelete(UTILITIES_URL, { data });
export default { search, verifyName, create, update, remove };
