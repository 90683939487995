import classNames from 'classnames';
import { Checkbox } from 'primereact/checkbox';
import { MultiSelect } from 'primereact/multiselect';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
/**
 *
 * @param {import('primereact/multiselect').MultiSelectProps} props
 */
export default function MultiSelectModify(props) {
    const [t] = useTranslation();
    // eslint-disable-next-line react/prop-types
    const { labelMaxSelectedItem = t('LABEL_CHECKBOX_ALL'), ...passProps } = props;
    // eslint-disable-next-line react/prop-types
    const maxSelect = props.options.length - 1;

    const panelHeaderTemplate = useCallback(
        /**
         *
         * @param {import('primereact/multiselect').MultiSelectPanelHeaderTemplateEvent} options
         */
        (options) => {
            const { checked, onChange, filterElement } = options;
            return (
                <>
                    {filterElement}
                    <div
                        className={classNames('p-multiselect-header bg-select border-bottom-0', {
                            'check-box-highlight': checked
                        })}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            onChange(e);
                        }}>
                        <Checkbox checked={checked} role='checkbox' />
                        <span className='w-100 fs-md'>{t('LABEL_CHECKBOX_ALL')}</span>
                    </div>
                </>
            );
        },
        [t]
    );
    return (
        <MultiSelect
            {...passProps}
            panelHeaderTemplate={panelHeaderTemplate}
            emptyFilterMessage={t('M008')}
            selectedItemsLabel={labelMaxSelectedItem}
            maxSelectedLabels={maxSelect}
        />
    );
}
