import { DEFAULT_SORT_FIELD, FIRST_PAGE, ID_FIELD, PER_PAGE, ROLE, SORT_ORDER } from 'app/constants';
import { commonService, userManagementService } from 'app/services';
import { isDirty } from 'app/utils/until';
import { roleSelectTemplate, useNoTemplate } from 'app/views/common/common-template';
import MultiSelectModify from 'app/views/common/multi-select';
import { HttpStatusCode } from 'axios';
import _ from 'lodash';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Paginator } from 'primereact/paginator';
import { Tooltip } from 'primereact/tooltip';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
// import { useSelector } from 'react-redux';
import moment from 'moment';
import EditAddUser from './add-edit-user';
export default function UserManagement() {
    // const userInfo = useSelector((states) => states.authentication.userInfo);
    const { t } = useTranslation();
    const addEditUser = useRef();
    const optionRole = ROLE;
    const inputSearch = useRef();
    const [first, setPaginator] = useState(FIRST_PAGE);
    const [selectedRole, setSelectedRole] = useState(optionRole.map((v) => v.value));
    const [searchFieldTyping, setSearchFieldTyping] = useState('');
    const [sortField, setSortField] = useState(DEFAULT_SORT_FIELD);
    const [sortOrder, setSortOrder] = useState(SORT_ORDER.DESC);
    const [total, setTotalRecords] = useState(0);
    const [data, setData] = useState([]);
    const [rows] = useState(PER_PAGE);
    const [oldFilter, setOldFilter] = useState(optionRole.map((v) => v.value));
    const mapRole = _.keyBy(optionRole, 'value');
    const noTemplate = useNoTemplate();
    //#region tooltip
    const tooltip = useRef();
    const [tooltipEnter, tooltipLeave] = useMemo(() => [(e) => tooltip.current.show(e), (e) => tooltip.current.hide(e)], []);
    //#endregion
    /**
     * fetch data from api
     * @param {Object} input
     * @returns
     */
    const fetchData = async (input) => {
        commonService.preloader.show();
        const payload = {
            searchField: searchFieldTyping.trim(),
            sortField,
            sortOrder,
            first,
            rows,
            selectedRole,
            ...input
        };
        const data = await userManagementService.search(payload);
        setData(data.data);
        setTotalRecords(data.total);
        commonService.preloader.hide();
        return true;
    };
    /**
     * re-load table and execute function to search data
     * @function fetchData Handle and display data on table
     */
    const onSearch = (e) => {
        e.preventDefault();
        setPaginator(FIRST_PAGE);
        fetchData({ first: FIRST_PAGE });
    };
    /**
     * re-load table and execute function to paginator data
     * @function fetchData Handle and display data on table
     */
    const onPage = (e) => {
        setPaginator(e.first);
        fetchData({ first: e.first });
    };
    /**
     * Handle sort option
     * @function fetchData Handle and Display data on table
     */
    const onSort = (e) => {
        setSortField(e.sortField);
        setSortOrder(e.sortOrder);
        fetchData({ sortField: e.sortField, sortOrder: e.sortOrder });
    };
    /**
     * On hide filter
     */
    const onHideFilter = async () => {
        if (isDirty(_.sortBy(oldFilter), _.sortBy(selectedRole)) || selectedRole.length !== oldFilter.length) {
            await fetchData();
            setOldFilter(selectedRole);
        }
    };
    /**
     * Edit user
     * @param {Object} rowData contains rows data
     */
    const onEdit = (rowData) => {
        addEditUser.current.show({ mode: false, ...rowData });
    };
    /**
     * Handle Delete Action
     * @param {Number} ID ID action
     */
    const remove = async (ID) => {
        const result = await commonService.modal.showConfirm({
            header: t('LABEL_DELETE_ITEM'),
            message: t('M016'),
            img: 'delete-message',
            customClass: 'warning-confirm-delete px-0'
        });
        if (result) {
            commonService.preloader.show();
            const res = await userManagementService.remove({ ID });
            if (res.status === HttpStatusCode.Ok) {
                commonService.toast.show({
                    severity: 'success',
                    summary: t('INFO_MESSAGE'),
                    detail: t('M018')
                });
                fetchData({ first: FIRST_PAGE });
                setPaginator(FIRST_PAGE);
                commonService.preloader.hide();
                return true;
            }
            commonService.preloader.hide();
            return commonService.toast.show({
                severity: 'error',
                summary: t('ERROR_MESSAGE'),
                detail: t('M017')
            });
        }
    };
    /**
     * Action template
     * @param {Object} rowData contains rows data
     */
    const actionTemplate = (rowData) => {
        return (
            <div className='d-flex justify-content-center'>
                <Button
                    data-pr-tooltip={t('BTN_UPDATE')}
                    onMouseEnter={tooltipEnter}
                    onMouseLeave={tooltipLeave}
                    variant='default'
                    className='btn-action-template'
                    onClick={() => onEdit(rowData)}
                    // disabled={userInfo.permission !== enumRole.admin || rowData.ID === userInfo.id}
                >
                    <span className='icon edit fs-md' />
                </Button>

                <Button
                    data-pr-tooltip={t('LABEL_TOOLTIP_ICON_REMOVE')}
                    onMouseEnter={tooltipEnter}
                    onMouseLeave={tooltipLeave}
                    variant='default'
                    className='btn-action-template'
                    onClick={() => {
                        remove(rowData.ID);
                    }}
                    // disabled={userInfo.permission !== enumRole.admin || rowData.ID === userInfo.id}
                >
                    <span className='icon delete fs-md' />
                </Button>
            </div>
        );
    };
    const birthdayTemplate = (rowData) => {
        return <div>{moment(rowData.Birthday).format('DD-MM-YYYY')}</div>;
    };
    const genderTemplate = (rowData) => {
        return <div>{rowData.Gender ? t('LABEL_FEMALE') : t('LABEL_MALE')}</div>;
    };
    /**
     * Add User
     */
    const onAddUser = () => {
        addEditUser.current.show({ mode: true });
    };
    /**
     * role template
     * @param {Object} rowData contains rows data
     */
    const roleTemplate = (rowData) => {
        return <div>{mapRole[rowData.Role].label}</div>;
    };

    /**
     * fetch data when change info user
     * @param {Boolean} flag
     */
    const onChangeUser = (flag) => {
        if (flag) {
            setPaginator(FIRST_PAGE);
            setSortField(DEFAULT_SORT_FIELD);
            setSortOrder(SORT_ORDER.DESC);
            fetchData({ sortField: DEFAULT_SORT_FIELD, sortOrder: SORT_ORDER.DESC, first: FIRST_PAGE });
        }
    };
    /**
     * Fetch first render data
     */
    useEffect(() => {
        commonService.preloader.show();
        fetchData();
        inputSearch.current.focus();
    }, []);
    return (
        <React.Fragment>
            <div
                className={
                    total <= rows
                        ? 'page-table d-flex flex-column fill-height table-common px-3'
                        : 'page-table d-flex flex-column fill-height table-pagination px-3'
                }>
                <div className='d-flex flex-column fill-height px-0 pt-2'>
                    <div className='h-100 d-flex flex-column rounded'>
                        <div className='col-12 row mx-0 py-2'>
                            <div className='w-40 py-2 row mx-0 px-0'>
                                <h1 className='my-0 fs-xxl fw-bold text-primary w-100 px-0 align-items-center d-flex'>
                                    {t('SIDEBAR_LABEL_USER_MANAGEMENT')}
                                </h1>
                            </div>
                            <div className='w-25 px-0 h-40-px'>
                                <form onSubmit={onSearch} className='position-relative width-input' autoComplete='off'>
                                    <InputText
                                        placeholder={t('PLACEHOLDER_ENTER_KEY_SEARCH')}
                                        className='ps-3 w-100'
                                        value={searchFieldTyping}
                                        ref={inputSearch}
                                        onChange={(e) => setSearchFieldTyping(e.target.value)}
                                    />
                                    <Button variant='custom-inline' type='submit'>
                                        <span className='icon search fs-md icon-search'></span>
                                    </Button>
                                </form>
                            </div>
                            <div className='w-15 px-0'>
                                <MultiSelectModify
                                    className='w-100 fs-md ms-3 multi-select-systems'
                                    value={selectedRole}
                                    labelMaxSelectedItem={t('USER_MANAGEMENT_LABEL_ALL_ROLE')}
                                    options={optionRole}
                                    onChange={(e) => {
                                        setSelectedRole(e.value);
                                    }}
                                    onHide={() => {
                                        onHideFilter();
                                    }}
                                    selectedItemTemplate={roleSelectTemplate}
                                />
                            </div>
                            <div className='w-20 px-0 d-flex justify-content-end'>
                                <Button
                                    className='btn-action-sm px-4'
                                    variant='default'
                                    // disabled={userInfo.permission !== enumRole.admin}
                                    onClick={onAddUser}>
                                    {t('BTN_ADD_USER')}
                                </Button>
                            </div>
                        </div>
                        <DataTable
                            value={data}
                            rows={rows}
                            first={first}
                            dataKey={ID_FIELD}
                            lazy
                            scrollable
                            totalRecords={total}
                            sortOrder={sortOrder}
                            sortField={sortField}
                            onSort={onSort}
                            onPage={onPage}
                            scrollHeight='flex'
                            className='fs-md mh-0 pt-2 fs-lg overflow-hidden table-container p-table-common'
                            emptyMessage={t('M008')}
                            tableClassName='table-striped table-hover'>
                            <Column
                                headerClassName='px-3'
                                header={t('TABLE_LABEL_NO')}
                                body={noTemplate}
                                className='fs-md px-3'
                                style={{ width: '5%' }}
                            />
                            <Column
                                field='UserName'
                                headerClassName='px-3'
                                header={t('TABLE_LABEL_ID')}
                                className='fs-md px-2'
                                sortable
                                style={{ width: '12.5%' }}
                            />
                            <Column
                                field='Name'
                                headerClassName='px-3'
                                header={t('TABLE_LABEL_NAME')}
                                className='fs-md px-2'
                                sortable
                                style={{ width: '22.5%' }}
                            />
                            <Column
                                field='Email'
                                headerClassName='px-3'
                                header={t('TABLE_LABEL_EMAIL')}
                                className='fs-md px-2'
                                sortable
                                style={{ width: '20%' }}
                            />
                            <Column
                                field='Birthday'
                                body={birthdayTemplate}
                                headerClassName='px-3'
                                header={t('TABLE_LABEL_BIRTH_DAY')}
                                className='fs-md px-2'
                                sortable
                                style={{ width: '10%' }}
                            />
                            <Column
                                field='Gender'
                                headerClassName='px-3'
                                body={genderTemplate}
                                header={t('TABLE_LABEL_GENDER')}
                                className='fs-md px-2'
                                sortable
                                style={{ width: '10%' }}
                            />
                            <Column
                                field='Role'
                                headerClassName='px-3'
                                body={roleTemplate}
                                header={t('TABLE_LABEL_ROLE')}
                                className='fs-md px-2'
                                style={{ width: '10%' }}
                            />
                            <Column
                                headerClassName='px-2 text-center'
                                header={t('TABLE_LABEL_ACTION')}
                                className='fs-md px-0 text-center'
                                body={actionTemplate}
                                style={{ width: '10%' }}
                            />
                        </DataTable>
                        <div className='pb-4'>
                            <Paginator
                                className={total > rows ? 'row pagination mx-0 d-block mh-50px' : 'd-none'}
                                rows={rows}
                                totalRecords={total}
                                first={first}
                                onPageChange={onPage}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <EditAddUser ref={addEditUser} onChange={onChangeUser} />
            <Tooltip
                ref={tooltip}
                className='init-tooltip group-tooltip'
                at='center bottom'
                my='left-8 top'
                style={{ visibility: 'hidden' }}
            />
        </React.Fragment>
    );
}
