import { SORT_ORDER, USER_MANAGEMENT, USER_MANAGEMENT_VERIFY_ACCOUNT_NAME } from 'app/constants';
import { requestDelete, requestGet, requestPost, requestPut } from 'app/utils/request';
import { HttpStatusCode } from 'axios';
/**
 * Handle get list user
 * @param {Object} value search content
 */
const search = async (value) => {
    // init params
    const { searchField, sortField, sortOrder, first, rows, selectedRole } = value;
    const params = {
        first,
        searchField,
        rows,
        sortField,
        sortOrder: sortOrder === 1 ? SORT_ORDER.ASC : SORT_ORDER.DESC,
        selectedRole
    };
    const res = await requestGet(USER_MANAGEMENT, { params }); // call api get data
    // get status and data from response
    const { status, data } = res;
    if (status === HttpStatusCode.Ok) {
        // return search data
        return { ...data.payload };
    } else {
        return {
            data: [],
            total: 0
        };
    }
};
/**
 * Request to verify user data from server
 * @param {Object} data store user management data
 */
const verifyUsername = (data) => requestPost(USER_MANAGEMENT_VERIFY_ACCOUNT_NAME, data);

/* Request to create user data from server
 * @param {Object} data store user management data
 */
const create = (data) => requestPost(USER_MANAGEMENT, data);
/* Request to create user data from server
 * @param {Object} data store user management data
 */
const update = (data) => requestPut(USER_MANAGEMENT, data);
/* Request to create user data from server
 * @param {Object} data store user management data
 */
const remove = (data) => requestDelete(USER_MANAGEMENT, { data });
/* Request to create user data from server
 * @param {Object} data store user management data
 */
export default { search, verifyUsername, create, update, remove };
