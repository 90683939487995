import { ROUTER } from 'app/constants';

export default function NotFound() {
    return (
        <div className='auto-container page-not-found'>
            <div className='page-content text-center'>
                <h1>404</h1>
                <h2>Oops! That page can’t be found</h2>
                <div className='text'>Sorry, but the page you are looking for does not existing</div>
                <a href={ROUTER.BASE_NAME} className='theme-btn btn-action-sm'>
                    Go to home page
                </a>
            </div>
        </div>
    );
}
