import classNames from 'classnames';
import { t } from 'i18next';
import { Component } from 'react';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
export const defaultState = {
    show: false,
    img: null,
    header: null,
    message: null,
    isConfirm: false,
    isDialog: false,
    isConfirmReload: false,
    buttons: [],
    customClass: null,
    btnOK: 'BTN_YES',
    btnCancel: 'BTN_NO',
    isTextLeft: false
};
export class CustomModal extends Component {
    constructor(props) {
        super(props);
        this.dialogStack = [];
        this.state = {
            ...defaultState
        };
    }
    /**
     *
     * @returns {Promise<Boolean>}
     */
    _show = (input, stack = false) => {
        if (stack) {
            return this.setState({
                ...defaultState,
                ...input
            });
        }
        return new Promise((done) => {
            if (this.state.show) {
                input.done = done;
                this.dialogStack.push(input);
            } else {
                this.setState({
                    ...defaultState,
                    ...input,
                    done
                });
            }
        });
    };
    /**
     * Show modal type error
     * @param {Object} data data to show
     */
    showError = (data) => {
        return this._show({
            btnOK: 'BTN_OK',
            ...data,
            show: true,
            isConfirm: false
        });
    };
    /**
     * Show modal type confirm
     * @param {*} data data to show
     */
    showConfirm = (data) => {
        return this._show({
            btnOK: 'BTN_YES',
            ...data,
            show: true,
            isConfirm: true
        });
    };
    /**
     * Show modal type confirm
     * @param {*} data data to show
     */
    showConfirmReload = (data) => {
        return this._show({
            ...data,
            show: true,
            isConfirmReload: true
        });
    };
    /**
     * Show modal type dialog
     * @param {*} data data to show
     */
    showDialog = (data) => {
        return this._show({
            ...data,
            show: true,
            isDialog: true
        });
    };
    /**
     * Hide modal
     * @function callback callback when hide modal
     */
    hideModal = (result) => {
        if (this.state.done) {
            this.state.done(result);
        }
        if (this.dialogStack.length) {
            this._show(this.dialogStack.shift(), true);
        } else {
            this.setState({ show: false });
        }
    };
    /**
     * Handle click close event
     */
    onClose = () => {
        this.hideModal(false);
    };
    /**
     * Handle click ok event
     */
    onOk = async () => {
        this.hideModal(true);
    };
    /**
     * Handle click cancel event
     */
    onCancel = () => {
        this.hideModal(false);
    };
    renderButton = () => {
        const { isDialog, isConfirm, buttons, isConfirmReload, btnOK, btnCancel } = this.state;
        if (isDialog) {
            return (
                <Row className='centered-control py-5'>
                    {buttons.map((v) => (
                        <Button key={v.message} className='btn-size-md' onClick={this.onClose}>
                            {v.message}
                        </Button>
                    ))}
                </Row>
            );
        }
        return (
            <div className='row justify-content-around pt-4 pb-3 border-top'>
                <div className='col-8 align-self-center d-flex' style={{ justifyContent: 'space-evenly' }}>
                    {isConfirm && (
                        <Button className='btn-cancel-sm' variant='default' onClick={this.onCancel}>
                            {t(btnCancel)}
                        </Button>
                    )}
                    {isConfirmReload && (
                        <Button className='btn-cancel-sm' variant='light' onClick={this.onClose}>
                            {t('BTN_LATER')}
                        </Button>
                    )}
                    <Button className='btn-action-sm' onClick={this.onOk} variant='warning'>
                        {t(btnOK)}
                    </Button>
                </div>
            </div>
        );
    };
    render() {
        const { img, header, message, show, customClass, bodyClassName, isTextLeft } = this.state;
        const imgClass = img ? classNames('dialog-img py-5', img) : '';

        return (
            <Modal show={show} centered className={customClass} backdrop='static'>
                <Modal.Body className='px-0'>
                    <Container fluid>
                        <Row>
                            <Col className='pt-3'>
                                <div className={imgClass} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <h2
                                    className='text-title-modal text-center fw-bold pt-4 pb-1'
                                    dangerouslySetInnerHTML={{
                                        __html: header
                                    }}></h2>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div
                                    className={classNames(img ? 'pb-5' : 'pb-4', bodyClassName, isTextLeft ? 'text-left' : 'text-center')}
                                    dangerouslySetInnerHTML={{
                                        __html: message
                                    }}
                                />
                            </Col>
                        </Row>
                        {this.renderButton()}
                    </Container>
                </Modal.Body>
            </Modal>
        );
    }
}

export default withTranslation('translation', { withRef: true })(CustomModal);
