import { ROOM_DETAIL_URL, ROOM_REMOVE_IMAGE_URL, ROOM_URL, ROOM_VERIFY_NAME } from 'app/constants';
import { requestDelete, requestGet, requestPost, requestPut } from 'app/utils/request';
import { HttpStatusCode } from 'axios';
/**
 * Handle get list ROOM
 * @param {Object} value search content
 */
const search = async (value) => {
    // init params
    const { searchField, selectedCategory } = value;
    const params = {
        searchField,
        selectedCategory
    };
    const res = await requestGet(ROOM_URL, { params }); // call api get data
    // get status and data from response
    const { status, data } = res;
    if (status === HttpStatusCode.Ok) {
        // return search data
        return data.payload;
    } else {
        return {
            data: []
        };
    }
};
const details = async (params) => {
    const res = await requestGet(ROOM_DETAIL_URL, { params }); // call api get data
    // get status and data from response
    const { status, data } = res;
    if (status === HttpStatusCode.Ok) {
        // return search data
        return data.payload;
    } else {
        return {
            data: []
        };
    }
};
/**
 * Request to verify user data from server
 * @param {Object} data store user management data
 */
const verifyUsername = (data) => requestPost(ROOM_VERIFY_NAME, data);

/* Request to create user data from server
 * @param {Object} data store user management data
 */
const create = (data) => requestPost(ROOM_URL, data);
/* Request to create user data from server
 * @param {Object} data store user management data
 */
const update = (data) => requestPut(ROOM_URL, data);
/* Request to create user data from server
 * @param {Object} data store user management data
 */
const remove = (data) => requestDelete(ROOM_URL, { data });
/* Request to create user data from server
 * @param {Object} data store user management data
 */
const removeImage = (data) => requestDelete(ROOM_REMOVE_IMAGE_URL, { data });
export default { search, verifyUsername, create, update, remove, details, removeImage };
